import axios from 'axios'
import isEqualWith from 'lodash/isEqualWith';
import { ANNOTATION_RESULT } from "@/constants/constants";
import { getAnnotation, postReviewResult, updateAnnotation } from '@/api/api'
import throttle from "lodash/throttle";
import {getBatch5Remaining} from "../api/api";

export default {
    namespaced: true,

    state: () => ({
        annotation: {},
        annotationSource: null,
        selected: new Set(),
        batch5Remaining: 1
    }),

    getters: {
        annotation: state => state.annotation,
        selected: state => state.selected,
        batch5Remaining: state => state.batch5Remaining
    },

    mutations: {
        batch5Remaining(state, remaining) {
            state.batch5Remaining = remaining
        },
        updateAnnotation(state, annotation) {
            state.annotation = annotation
        },
        updateAnnotationSource(state, source) {
            state.annotationSource = source
        },
        updateSelected(state, imageId) {
            if (state.selected.has(imageId)) {
                state.selected.delete(imageId);
            } else {
                state.selected.add(imageId);
            }
        },
        updateSelectedSet(state, selectedSet) {
            state.selected = selectedSet
        }
    },

    actions: {
        getBatch5Remaining: throttle(async function({ commit }) {
            const { data } = await getBatch5Remaining();
            commit("batch5Remaining", data)
        }, 3000),
        async fetchAnnotation({ state, commit }, id) {
            commit('updateAnnotation', {})
            if (state.annotationSource) {
                state.annotationSource.cancel()
            }
            commit('updateAnnotationSource', axios.CancelToken.source())
            const { data } = await getAnnotation(id)
            commit('updateAnnotationSource', null)
            if (!data) return
            commit('updateAnnotation', data)

            const selectedSet = new Set()
            for (const image of data.images) {
                if (image.annotationResult === ANNOTATION_RESULT.SELECTED) {
                    selectedSet.add(image.imageId);
                }
            }
            commit('updateSelectedSet', selectedSet)
        },
        async submitReview({ rootGetters }, { annotationId, reviewResult }) {
            const payload = {
                userToken: rootGetters['auth/user'].token,
                createTimestamp: Date.now(),
                annotationId,
                reviewResult
            }
            await postReviewResult(payload)
        },
        selectImage({ commit }, imageId) {
            commit('updateSelected', imageId)
        },
        async submitAnnotation({ state }, annotationId) {
            const annotatedImages = Object.values(state.annotation.images).map(({ imageId }) => {
                return {
                    imageId,
                    result: state.selected.has(imageId)
                        ? ANNOTATION_RESULT.SELECTED
                        : ANNOTATION_RESULT.NOT_SELECTED,
                };
            });

            const payload = {
                updateTimestamp: Date.now(),
                annotatedImages
            };

            await updateAnnotation(annotationId, payload);
        },
        async checkAnnotation({ state }, annotationId) {
            const { data } = await getAnnotation(annotationId)
            const comparator = (x) => {
                // skip comparing s3 url because of presigned token
                if(typeof x === 'string' && x.includes('s3.amazonaws.com')) return true
            }

            return isEqualWith(state.annotation, data, comparator)
        }
    },
}