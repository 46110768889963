<template>
  <nav class="navbar navbar-expand-sm navbar-dark px-3 py-0">
    <div class="container-fluid px-0">
      <router-link class="navbar-brand" to="/annotator/stats">
        <img src="@/assets/img/logo.svg" alt="Platinum" width="144" class="me-4">
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        v-if="isLoggedIn"
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              aria-expanded="false"
            >
              Annotate
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/annotate/depth"
                  >Depth</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/annotate/quality"
                  >Depth Quality</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/annotate/spoof-quality"
                  >Spoof Quality</router-link
                >
              </li>
              <!-- TODO: temporary removed -->
<!--              <li>-->
<!--                <router-link class="dropdown-item" to="/annotate/quality-check"-->
<!--                  >Quality Check</router-link-->
<!--                >-->
<!--              </li>-->
              <!-- <li>
                <router-link class="dropdown-item" to="/annotate/real-quality"
                  >Real Quality</router-link
                >
              </li> -->
              <!-- <li>
                <router-link class="dropdown-item" to="/annotate/match"
                  >Match</router-link
                >
              </li> -->
              <li v-if="isManager">
                <router-link class="dropdown-item" to="/annotate/review-quality"
                >Review Quality</router-link
                >
              </li>
              <li v-if="batch5Remaining > 0">
                <router-link class="dropdown-item" to="/annotate/review-batch5">Review Batch#5 Remains: {{batch5Remaining}}</router-link>
              </li>
              <li v-if="batch5Remaining < 1">
                <router-link class="dropdown-item" to="/annotate/review-batch6" >Review Batch#6</router-link>
              </li>
            </ul>
          </li>

          <li v-if="isManager" class="nav-item">
            <router-link class="nav-link" to="/managers-review">Manager's Review</router-link>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              aria-expanded="false"
            >
              Practice
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/practice/depth"
                  >Depth</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/practice/quality"
                  >Quality</router-link
                >
              </li>
            </ul>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/result"> Result </router-link>
          </li> -->
          <!-- <li v-if="isManager" class="nav-item">
            <router-link class="nav-link" to="/users"> Users </router-link>
          </li> -->
        </ul>
        <div class="d-flex align-items-center">
          <i
            class="me-1 text-grey bi bi-arrow-clockwise cursor-pointer"
            @click="getStatsToday"
          ></i>
          <router-link to="/annotator/stats" custom v-slot="{ navigate }">
            <div
              class="me-3 text-grey text-end text-xs lh-sm cursor-pointer"
              @click="navigate"
            >
              <div>{{ statsToday.totalSessions }} images</div>
              <div>Reviewed today</div>
            </div>
          </router-link>
          <div class="me-3 text-light">
            Hi, {{ googleUser.name }}
          </div>
          <div class="btn-group">
            <a href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
              <img src="@/assets/img/ic_nav.svg" alt="nav" width="17">
            </a>
            <ul class="dropdown-menu dropdown-menu-end mnu-right" aria-labelledby="defaultDropdown">
              <li>
                <div class="dv-stats">
                  {{ statsToday.totalSessions }} sets
                </div>
              </li>
              <li class="dropdown-item" @click="gotoPage('AnnotatorStats')">
                <a class="statistics-item" href="javascript:void(0)">Statistics</a>
              </li>
              <!-- <li v-if="isManager" class="dropdown-item" @click="gotoPage('ManagersReview')">
                <a class="managers-review-item" href="javascript:void(0)">Manager's Review</a>
              </li>               -->
              <li v-if="isManager" class="dropdown-item" @click="gotoPage('Users')">
                <a class="access-management-item" href="javascript:void(0)">Access Management</a>
              </li>
              <li @click="logOut" class="dropdown-item"><a class="logout-item" href="javascript:void(0)">Log out</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TopNavbar",
  // data() {
  //   return {
  //     batch5Remaining: 1
  //   };
  // },
  computed: {
    ...mapGetters("auth", ["googleUser", "user", "isLoggedIn", "isManager"]),
    ...mapGetters("stats", ["statsToday"]),
    ...mapGetters("review", ["batch5Remaining"]),
  },
  created() {
    if (this.isLoggedIn) {
      this.getStatsToday();
      this.getBatch5Remaining();
    }
  },
  methods: {
    ...mapActions("auth", ["logOut"]),
    ...mapActions("stats", ["getStatsToday"]),
    ...mapActions("review", ["getBatch5Remaining"]),
    gotoPage(name) {
      this.$router.push({ name: name })
    }
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: #02364A;
  z-index: 3
}
.text-grey {
  color: #819ba5;
}
.text-xs {
  font-size: 0.75rem;
}

.nav-item {
  padding: 0 15px
}

.mnu-right {
  min-width: 212px;
  margin-top: 19px
}

.mnu-right .dropdown-item {
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.mnu-right .dropdown-item a {
  color: #333333;
  text-decoration: none;
  padding-left: 30px!important;
}

.mnu-right .dropdown-item:hover a {
  color: #FFF;
}

.mnu-right .dropdown-item a.logout-item{
  background: url('@/assets/img/ic_log-out_g.svg') no-repeat left;
}

.mnu-right .dropdown-item:hover a.logout-item{
  color: #FFF;
  background: url('@/assets/img/ic_log-out_w.svg') no-repeat left;
}

.mnu-right .dropdown-item a.statistics-item{
  background: url('@/assets/img/ic_statistic_g.svg') no-repeat left;
}

.mnu-right .dropdown-item:hover a.statistics-item {
  color: #FFF;
  background: url('@/assets/img/ic_statistic_w.svg') no-repeat left;
}

.mnu-right .dropdown-item a.access-management-item{
  background: url('@/assets/img/ic_access-management_g.svg') no-repeat left;
}

.mnu-right .dropdown-item:hover a.access-management-item {
  color: #FFF;
  background: url('@/assets/img/ic_access-management_w.svg') no-repeat left;
}

.mnu-right .dropdown-item a.managers-review-item{
  background: url('@/assets/img/ic_access-management_g.svg') no-repeat left;
}

.mnu-right .dropdown-item:hover a.managers-review-item {
  color: #FFF;
  background: url('@/assets/img/ic_access-management_w.svg') no-repeat left;
}

.mnu-right .dv-stats {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #02364A;
  padding: 12px 10px 20px 10px;
  border-bottom-style: dashed;
  border-color: #CCC;
  margin: 8px;
}

.dropdown-toggle::after {
  vertical-align: 0.1em;
  border-top: 0.45em solid;
  border-right: 0.45em solid transparent;
  border-left: 0.45em solid transparent;
  -moz-transition: all .15s ease-in-out;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
}

.dropdown:hover .dropdown-toggle::after {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dropdown-toggle.show::after {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.nav-link {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFF;
  padding: 20px!important
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  background-color: #001923;
  color: #B0D7ED
}

.dropdown-item {
  padding: 8px 12px;
  font-size: 14px
}

.dropdown-item:hover {
  color: #FFF;
  background-color: #7AA3BA;
}

.dropdown-item.active {
  background-color: #02364A;
}

.dropdown-menu {
  border-radius: 0;
  border: 0;
  box-shadow: var(--bs-box-shadow-sm);
}

@media (min-width: 576px) {
  .dropdown:hover > .dropdown-menu,
  .dropend:hover > .dropdown-menu {
    display: block;

  }

  .dropdown .dropdown-menu {
    display: none;
    -moz-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
}
</style>
